import React, { useState, useEffect } from 'react'
import { Button, Icon, Div, Text } from 'atomize'
import PrimaryButton from 'components/Buttons/PrimaryButton'

import { MAX_MINT } from 'config/constants/mint'

import useNotification from 'hooks/useNotification'
import { useSeason1MintContract } from 'hooks/useContract'
import { formatEther } from 'ethers/lib/utils'


const MintButton = ({ bg, hoverBg, merkleProof, onConfirm, isPrivateMint,  ...props }) => {
    const { signer: season1MintContract } = useSeason1MintContract();

    const { notificationError } = useNotification();
    const [pendingTx, setPendingTx] = useState(false);
    const [counter, setCounter] = useState(1);
    const [mintPrice, setMintPrice] = useState(0);

    useEffect(() => {
        const fetchMintPrice = async () => {
            try {
                const priceInWei = await season1MintContract.mintPrice();
                const priceInEther = parseFloat(formatEther(priceInWei));
                setMintPrice(priceInEther);
            } catch (error) {
                console.error("Erreur lors de la récupération du prix de mint:", error);
            }
        };
        fetchMintPrice();
    }, [season1MintContract]);

    const price = +(mintPrice * counter).toFixed(4);
    const text = 'Mint ' + counter + ' ' + 'NFT';

    const updateCounter = (value) => {
        if (value >= 1 && value <= MAX_MINT) {
            setCounter(value);
        }
    };

    return (
        <Div d="flex" flexDir="row" align="center" w={{ xs: '100%', sm: 'auto' }}>
            {!isPrivateMint ?
                <Button
                    h="2rem"
                    w="2rem"
                    bg={bg}
                    hoverBg={hoverBg}
                    rounded="lg"
                    m={{r: '1rem'}}
                    onClick={() => updateCounter(counter - 1)}
                >
                    <Icon name="Minus" size="20px" color="darkColor"/>
                </Button> : <></>
            }

            <PrimaryButton
                bg={bg}
                hoverBg={hoverBg}
                textColor="darkColor"
                w={{ xs: '100%', sm: '10rem' }}
                h="3.5rem"
                isLoading={pendingTx}
                disabled={pendingTx}
                onClick={async () => {
                    setPendingTx(true);
                    try {
                        await onConfirm(counter, merkleProof);
                    } catch (e) { // most likely rejected by user
                        notificationError('Error', 'Please try again. Confirm the transaction and make sure you are paying enough gas!' );
                    } finally {
                        setPendingTx(false);
                    }
                }}
                {...props}
            >

                {pendingTx ?
                    'Minting...' :
                    <Div d="flex" flexDir="column">
                        <Text textWeight="800" textColor="darkColor">{text}</Text>
                        <Text tag="em" textSize="caption" textColor="darkColor">{price} ETH</Text>
                    </Div>
                }
            </PrimaryButton>

            {!isPrivateMint ?
                <Button
                    h="2rem"
                    w="2rem"
                    bg={bg}
                    hoverBg={hoverBg}
                    rounded="lg"
                    m={{ l: '1rem' }}
                    onClick={() => updateCounter(counter + 1)}
                >
                    <Icon name="Plus" size="20px" color="darkColor" />
                </Button> : <></>
            }
        </Div>
    );
};

export default MintButton;