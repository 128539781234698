import getGasPrice from 'utils/getGasPrice'
import { getErrorMessage } from '../format'
import { BigNumber } from 'ethers'

const defaultOptions = { gasLimit: BigNumber.from(600000), gasPrice: getGasPrice() };

// as this is a specific function, gas price and gas limit are important
export const mintAmount = async (season1MintContract, amount, isPrivateMint, merkleProof) => {
    try {
        const price = await season1MintContract.mintPrice();
        const value = price.mul(BigNumber.from(amount));

        const providerGasPrice = await season1MintContract.provider.getGasPrice();
        let estimatedGasLimit;
        if(isPrivateMint)
            estimatedGasLimit = await season1MintContract.estimateGas.whitelistMint(merkleProof, { value });
        else
            estimatedGasLimit = await season1MintContract.estimateGas.mint(amount, merkleProof, { value });

        const gasLimit = estimatedGasLimit?.toNumber() || defaultOptions.gasLimit;
        const gasPrice = providerGasPrice || defaultOptions.gasPrice;
        if(isPrivateMint)
            return season1MintContract.whitelistMint(merkleProof, { gasLimit, gasPrice, value });
        return season1MintContract.mint(amount, merkleProof, { gasLimit, gasPrice, value });

    } catch (error) {
        return { error: true, message: getErrorMessage(error ? Object.entries(error).map((r) => r[1].message) : 'Unknown error') };
    }
}